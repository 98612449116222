.portfolio-button {
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.popup {
    background-image: none;
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #000;
    color: #fff;
    padding: 15px 20px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    animation: fadeInOut 3s ease;
}

@keyframes fadeInOut {
    0% {
        opacity: 0;
        transform: translateY(10px);
    }

    10% {
        opacity: 1;
        transform: translateY(0);
    }

    90% {
        opacity: 1;
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        transform: translateY(10px);
    }
}

.greentech__header {
    display: flex;
    flex-direction: row;
}

.greentech__header-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    margin-right: 5rem;
}

.greentech__header-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 125px;
    line-height: 120px;
    padding: 1.5rem;
    letter-spacing: -0.04em;
}

.greentech__header-content p {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: var(--color-text);

    margin-top: 1.5rem;
    cursor: pointer;
    transition: color 0.3s ease;
    position: relative;
}

.greentech__header-content p:hover::after {
    top: 0;
    left: 0;
    position: absolute;
    content: "Because our planet is not an iheritance of our fathers, but a loan from our children";
    font-family: var(--font-family);
    font-weight: 900;
    font-size: 20px;
    line-height: 28px;
    color: var(--color-text);
    white-space: nowrap;
}

.greentech__header-content p:hover,
.greentech__header-content p:focus {
    color: transparent;
}

.greentech__header-content__input {
    width: 100%;
    margin: 1rem 0 1rem;

    display: flex;
    flex-direction: row;
}

.greentech__header-content__input input {
    flex: 2;
    width: 100%;
    height: 17px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: var(--gradient-text);
    border: 2px solid var(--gradient-text);
    padding: 0 1rem;
    outline: none;
    color: #fff;

    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.greentech__header-content__input button {
    flex: 0.6;
    width: 100%;
    height: 17px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: #FF4820;
    border: 2px solid #FF4820;
    padding: 0 1rem;
    color: #fff;
    cursor: pointer;
    outline: none;

    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.greentech__header-content__people {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;

    margin-top: 2rem;
}

.greentech__header-content__people img {
    width: 181.79px;
    height: 38px;
}

.greentech__header-content__people p {
    margin: 0 0 0 1rem;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 12px;
    line-height: 38px;
    color: #fff;
    text-align: center;
}

.greentech__header-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navpad__container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5rem;
}

.navpad {
    z-index: 9;
    width: 100%;
    height: 450px;
    max-width: 2030px;
    padding: 0.5rem;
    background-color: none;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-column-gap: 25px;
    grid-row-gap: 25px;
    margin: 0 auto;
    position: relative;
}

@media (max-width: 768px) {
    .navpad {
        grid-template-columns: repeat(2, 1fr);
        padding: 1rem;
        max-width: 90%;
    }

    .navpad div {
        padding: 2rem;
    }
}

@media (max-width: 480px) {
    .navpad {
        grid-template-columns: 1fr;
        max-width: 100%;
    }
}

@media (max-width: 200px) {
    .navpad {
        grid-template-columns: 1fr;
        max-width: 100%;
    }
}

.navpad div {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
    background-image: url('/src/assets/navpadback.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 14px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: white;
    filter: brightness(1);
}

.navpad div:hover {
    background-color: hsla(36, 0%, 0%, 0.2);
    transform: translateY(-10px) scale(1.05);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    filter: brightness(1);
}

.navpad:hover div:not(:hover) {
    filter: brightness(0.5);
    /* Dim non-hovered elements only when hovering on the parent */
}

.div1 {
    padding: 0.5rem;
    background-color: black;
    grid-area: 3 / 3 / 5 / 7;
}

.div2 {
    background-color: black;
    grid-area: 1 / 1 / 5 / 3;
}

.div3 {
    background-color: black;
    grid-area: 1 / 3 / 3 / 5;
}

.div4 {
    background-color: black;
    grid-area: 1 / 5 / 3 / 7;
}

.div5 {
    background-color: black;
    grid-area: 5 / 1 / 7 / 3;
}

.div6 {
    background-color: black;
    grid-area: 5 / 3 / 7 / 5;
}

.div7 {
    background-color: black;
    grid-area: 5 / 5 / 7 / 7;
}

.greentech__header-image img {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 1050px) {
    .greentech__header {
        flex-direction: column;
    }

    .greentech__header-content {
        margin: 0 0 3rem;
    }
}

@media screen and (max-width: 650px) {
    .greentech__header-content h1 {
        font-size: 48px;
        line-height: 60px;
    }

    .greentech__header-content p {
        font-size: 16px;
        line-height: 24px;
    }

    .greentech__header-content__people {
        flex-direction: column;
    }

    .greentech__header-content__people p {
        margin: 0;
    }

    .greentech__header-content__input input,
    .greentech__header-content__input button {
        font-size: 16px;
        line-height: 24px;
    }
}

@media screen and (max-width: 490px) {

    .greentech__header-content p:hover::after {
        top: 0;
        left: 0;
        position: absolute;
        height: 100px;
        content: "Because our planet is not an iheritance of our fathers, but a loan from our children";
        font-family: var(--font-family);
        font-weight: 900;
        font-size: 20px;
        line-height: 28px;
        color: var(--color-text);
        white-space: normal;
        max-width: 90%;
        word-wrap: break-word;
        overflow-wrap: break-word;
    }

    .greentech_header .hero_padding {
        padding: 0;
    }

    .navpad {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(7, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 10px;
        margin-bottom: 200px;
    }

    .div1 {
        grid-area: 1 / 1 / 2 / 2;
    }

    .div2 {
        grid-area: 2 / 1 / 3 / 2;
    }

    .div3 {
        grid-area: 3 / 1 / 4 / 2;
    }

    .div4 {
        grid-area: 4 / 1 / 5 / 2;
    }

    .div5 {
        grid-area: 5 / 1 / 6 / 2;
    }

    .div6 {
        grid-area: 6 / 1 / 7 / 2;
    }

    .div7 {
        grid-area: 7 / 1 / 8 / 2;
    }

    .greentech__header-content h1 {
        font-size: 36px;
        line-height: 48px;
    }

    .greentech__header-content p {
        font-size: 14px;
        line-height: 24px;
    }

    .greentech__header-content__input input,
    .greentech__header-content__input button {
        font-size: 12px;
        line-height: 16px;
    }
}