.greentech__brand p {
    width: 250px;
    justify-self: center;
    align-items: center;
    font-family: var(--font-family);
    font-size: 17px;
    line-height: 20px;
    color: #fff;
}

.greentech__brand {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.greentech__brand div {
    flex: 1;
    max-width: 15px;
    min-width: 120px;
    margin: 1rem;

    display: flex;
    justify-content: center;
    align-items: center;
}

#bicarjet {
    border: none;
    width: 60px;
}