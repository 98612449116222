@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

:root {
  --font-family: 'Manrope', sans-serif;

  --gradient-text: linear-gradient(89.97deg, #67fa8c 1.84%, #5ba56d 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #042c54 -13.86%, #67f488 99.55%);

  --color-bg: #020b15;
  --color-footer: #459d5b;
  --color-blog: #042c54;
  --color-text: #5ba56d;
  --color-subtext: #FF8A71;
}