.greentech__blog-container_article {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    background: var(--gradient-bar);
}

.greentech__blog-container_article-image {
    width: 100%;
    height: 100%;

}

.greentech__blog-container_article-image img {
    width: 100%;
    height: 100%;
}

.greentech__blog-container_article-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 1rem 1.5rem;
    height: 100%;
}

.greentech__blog-container_article-content p {
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 11.649px;
    line-height: 35px;
    color: #FFFFFF;
}

.greentech__blog-container_article-content h3 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 19.3154px;
    line-height: 30px;
    cursor: pointer;
    margin-bottom: 5rem;
    color: #FFFFFF;
}

.greentech__blog-container_article-content p:last-child {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 700;
    font-size: 11.649px;
    line-height: 35px;
    color: #FFFFFF;
    cursor: pointer;
}

@media screen and (max-width: 550px) {
    .greentech__blog-container_article-content h3 {
        font-size: 10px;
        line-height: 25px;
    }
}