.gwtcompanyrouter {
   margin: 0;
   padding: 0;
}

.gwtrouting_container {
   position: relative;
   bottom: 3rem;
   display: inline-flex;
   margin-left: 6rem;
   justify-content: center;
   align-items: center;
}

.gwtrouting_container button {
   margin: 0rem 0.1rem;
   position: relative;
   padding: 0.5rem 1rem;
   color: #fff;
   background: var(--color-footer);
   font-family: var(--font-family);
   font-weight: 500;
   font-size: 18px;
   line-height: 20px;
   border: none;
   outline: none;
   cursor: pointer;
}