/* Modal Styles */
.modal-overlay {
    font-family: var(--font-family);
    position: fixed;
    top: 0;
    left: 7rem;
    width: 80vw;
    height: 80vh;
    /* Dark overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    /* Ensure it is above other content */
}

.modal-content {
    background: -moz-radial-gradient(circle at 3% 25%, rgb(0, 83, 28) 0%, rgb(30, 161, 74) 100%);

    background: -webkit-radial-gradient(circle at 3% 25%, rgb(0, 83, 37) 0%, rgba(4, 12, 24, 1) 100%);

    background: -o-radial-gradient(circle at 3% 25%, rgb(0, 83, 33) 0%, rgba(4, 12, 24, 1) 100%);

    background: -ms-radial-gradient(circle at 3% 25%, rgb(0, 83, 0) 0%, rgba(4, 12, 24, 1) 100%);

    background: radial-gradient(circle at 3% 25%, rgb(4, 12, 20) 0%, rgba(4, 12, 24, 1) 100%);
    width: 80%;
    max-width: 900px;
    min-width: 500px;
    /* Minimum width for smaller screens */
    max-height: 90vh;
    /* Restrict height to be scrollable if needed */
    border-radius: 8px;
    /* Rounded corners */
    padding: 30px;
    /* Adjust padding for better spacing */
    overflow-y: auto;
    /* Scrollable content */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    /* Subtle shadow for depth */
    position: relative;
    /* For the close button */
}

.close-button {
    position: sticky;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    font-weight: bold;
    color: #ffffff;
    /* Dark text for close button */
    cursor: pointer;
    line-height: 1;
}

.close-button:hover {
    color: #ff0000;
    /* Highlight close button on hover */
}

.modal-body {
    color: #ffffff;
    /* General text color */
    font-size: 1rem;
    /* Base font size */
    line-height: 1.6;
    /* Better readability */
}

.modal-body h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.modal-body p {
    margin: 10px 0;
}

.modal-body ul {
    padding-left: 20px;
}

.modal-body ul li {
    margin: 5px 0;
}

/* Media Queries for Smaller Screens */
@media (max-width: 1024px) {
    .modal-content {
        width: 85%;
        /* Reduce width slightly */
        padding: 20px;
        /* Adjust padding */
    }
}

@media (max-width: 768px) {
    .modal-content {
        width: 90%;
        /* Almost full width */
        padding: 15px;
        /* Reduce padding for smaller devices */
    }
}

@media (max-width: 480px) {
    .modal-content {
        width: 95%;
        /* Full width for very small screens */
        padding: 45px;
    }

    .close-button {
        font-size: 1.2rem;
    }

    .modal-body {
        font-size: 0.9rem;
        /* Smaller font for readability */
    }
}

.greentech__footer {
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;

    /* ff 3.6+ */
    background-color: var(--gradient-bar);
}

.greentech__footer-heading {
    width: 100%;
    text-align: center;

    margin-bottom: 3rem;
}

.greentech__footer-heading h1 {
    padding: 2rem;
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 64px;
    line-height: 75px;
}

.greentech__footer-btn {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 1rem;
    border: 1px solid #fff;
    text-align: center;

    margin-bottom: 5rem;
    cursor: pointer;
}

.greentech__footer-btn p {
    font-size: 18px;
    font-family: var(--font-family);
    line-height: 21px;
    color: #fff;
    word-spacing: 2px;
}

.greentech__footer-links {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;

    width: 100%;
    text-align: left;
}

.greentech__footer-links div {
    width: 250px;
    margin: 1rem;
}

.greentech__footer-links_logo {
    display: flex;
    flex-direction: column;
}

.greentech__footer-links_logo img {
    width: 100px;

    margin-bottom: 1rem;
}

.greentech__footer-links_logo p {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 15px;
    color: #fff;
}

.greentech__footer-links_div {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.greentech__footer-links_div h4 {
    font-size: 14px;
    line-height: 17px;
    font-family: var(--font-family);
    color: #fff;

    margin-bottom: 0.9rem;
}

.greentech__footer-links_div p {
    font-size: 12px;
    line-height: 15px;
    font-family: var(--font-family);
    color: #fff;

    margin: 0.5rem 0;
    cursor: pointer;
}

.greentech__footer-copyright {
    margin-top: 2rem;
    text-align: center;
    width: 100%;
}

.greentech__footer-copyright p {
    font-size: 12px;
    font-family: var(--font-family);
    line-height: 15px;
    color: #fff;
}

@media screen and (max-width: 850px) {
    .greentech__footer-heading h1 {
        font-size: 44px;
        line-height: 50px;
    }
}

@media screen and (max-width: 550px) {
    .greentech__footer-heading h1 {
        font-size: 34px;
        line-height: 42px;
    }

    .greentech__footer-links div {
        margin: 1rem 0;
    }

    .greentech__footer-btn p {
        font-size: 14px;
        line-height: 20px;
    }
}

@media screen and (max-width: 400px) {
    .greentech__footer-heading h1 {
        font-size: 27px;
        line-height: 38px;
    }
}