.flex__center {
   display: flex;
   justify-content: center;
   align-items: center;
}

.app__gallery {
   flex-direction: row;

   background: var(--color-black);
   padding: 0rem 0 1rem 6rem;
}

.app__gallery-content {
   flex: 1;
   display: flex;
   justify-content: center;
   align-items: flex-start;
   flex-direction: column;

   min-width: 200px;
   padding-right: 2rem;
}

.app__gallery-content h1 {
   color: white;
   font-family: var(--font-family);

}

#findus {
   font-family: var(--font-family);
   margin-left: 0;
}

.map {
   padding: 0rem 0rem;
   background-color: transparent;
}

.app__gallery-content button {
   margin-top: 1rem;
}

.app__gallery-images {
   flex: 1;
   display: flex;
   flex-direction: row;
   max-width: 80%;
   position: relative;
   margin-right: 6rem;
}

.border {
   border-left: 5px solid #85ffbea0;
}

.app__gallery-images_container {
   display: flex;
   flex-direction: row;
   width: max-content;
   overflow-x: scroll;
   -ms-overflow-style: none;
   scrollbar-width: none;

}

.app__gallery-images_container::-webkit-scrollbar {
   display: none;
}

.app__gallery-images_card {
   position: relative;
   min-width: 451px;
   height: 447px;
   margin-right: 1rem;
}

.gallery__image-icon {
   position: absolute;
   color: #fff;
   font-size: 2rem;
   opacity: 0;
   transition: .5s ease;
   cursor: pointer;
}

.app__gallery-images_card img {
   width: 100%;
   height: 100%;
   object-fit: cover;
   opacity: 1;
   transition: .5s ease;
}

.app__gallery-images_card:hover img {
   opacity: .35;
}

.app__gallery-images_card:hover .gallery__image-icon {
   opacity: 1;
}

.app__gallery-images_arrows {
   width: 100%;
   display: flex;
   justify-content: space-between;
   align-items: center;

   padding: 0 1rem;
   position: absolute;
   bottom: 5%;
}

.gallery__arrow-icon {
   color: white;
   font-size: 5rem;
   cursor: pointer;
   background: var(--color-black);
   border-radius: 5px;
}

.gallery__arrow-icon:hover {
   color: var(--color-white);
}

#gallery__title {
   color: var(--color-text);
   font-family: var(--font-family);
   margin-left: 4rem;
}

@media screen and (min-width: 2000px) {
   .app__gallery-content button {
      margin-top: 2rem;
   }

   .app__gallery-content {
      min-width: 1000px;
      padding-right: 4rem;
   }

   .app__gallery-images_card {
      min-width: 400px;
      height: 547px;
   }
}

@media screen and (max-width: 1150px) {
   .app__gallery {
      flex-direction: column;
      margin-right: 3rem;
   }

   .app__gallery-images {
      max-width: 100%;
      margin: 5rem 0;
   }
}

@media screen and (max-width: 850px) {
   .app__gallery {
      padding: 4rem 0 4rem 4rem;
   }
}

@media screen and (max-width: 650px) {

   .map {
      padding: 0 0 0 0;
      margin-left: 1rem;
   }

   #findus {
      margin-top: 7rem;
      padding: 0;
   }

   .flex__center {
      padding: 0;
      margin: 0 1rem 0 0;
   }

   .app__gallery {
      height: 400px;
      padding: 1rem 0 0rem 1rem;
   }

   .app__gallery-content {
      min-width: 100%;
   }

   .app__gallery-images_card {
      min-width: 240px;
      height: 320px;
   }
}