@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

:root {
   --font-family: 'Manrope', sans-serif;

   --gradient-text: linear-gradient(89.97deg, #67fa8c 1.84%, #5ba56d 102.67%);
   --gradient-bar: linear-gradient(103.22deg, #042c54 -13.86%, #67f488 99.55%);

   --color-bg: #020b15;
   --color-footer: #459d5b;
   --color-blog: #042c54;
   --color-text: #5ba56d;
   --color-subtext: #FF8A71;
}

body {
   background-color: var(--color-bg);
   color: var(--color-text);
   font-family: var(--font-family);
}

.team-section {
   text-align: center;
   padding: 4rem 2rem;
   background: var(--color-bg);
}

.team-section h2 {
   font-size: 2.5rem;
   font-weight: 700;
   background: var(--gradient-text);
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   margin-bottom: 2rem;
}

.team-list {
   display: flex;
   justify-content: center;
   gap: 2rem;
   flex-wrap: wrap;
}

.team-member-card {
   background-color: var(--color-blog);
   border-radius: 8px;
   padding: 1.5rem;
   text-align: center;
   width: 300px;
   transition: transform 0.3s ease, box-shadow 0.3s ease;
   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.team-member-card:hover {
   transform: translateY(-5px);
   box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3);
}

.team-member-card img {
   width: 150px;
   height: 150px;
   border-radius: 10%;
   margin-bottom: 1rem;
   border: 3px solid var(--color-text);
}

.team-member-card h3 {
   font-size: 1.5rem;
   font-weight: 600;
   color: var(--color-text);
}

.team-member-card p {
   font-size: 1rem;
   font-weight: 300;
   color: var(--color-subtext);
   margin: 0.5rem 0;
}

.team-member-card a {
   display: inline-block;
   margin: 0.5rem;
   text-decoration: none;
   color: var(--color-text);
   font-weight: 500;
}

.team-member-card a:hover {
   color: var(--color-footer);
}